<template>

    <Container title="License Manager" :current-name="$options.name" :items="items" >

        <div id="no-more-tables">
            <table class="table table-borderless tablex">
                <thead>
                <tr>
                    <th scope="col">Product</th>
                    <th scope="col">สถานะ</th>
                    <th scope="col">License</th>
                    <th scope="col">ฟังก์ชั่น</th>
                    <th scope="col">ยอดค้างชำระ</th>
                    <th scope="col">กำหนดจ่าย</th>
                    <th scope="col">การชำระ</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in list_license" :key="item.id" class="pb-1">
                    <td scope="row" data-title="Product">
                        <b-img v-if="item.product === 'scb'" src="../assets/img/scb.jpeg" height="41"></b-img>
                        <span v-else>#</span>
                    </td>
                    <td data-title="สถานะ" v-if="item.status != null" :class="[item.status  ? '': 'text-colorxred']">{{ item.status  ? "ปกติ": "ระงับ"}}</td>
                    <td data-title="License">{{ item.key }}</td>
                    <td data-title="ฟังก์ชั่น">{{ vTofunction(item.version )}}</td>
                    <td data-title="ยอดค้างชำระ">{{ item.amount | formatNumber}}</td>
                    <td data-title="กำหนดจ่าย">ทุกวันที่ {{item.payment_day_due}}</td>
                    <td data-title="การชำระ" v-html="dateCalculate(item)"></td>
                    <td data-title=""><b-button class="btn-success">ชำระ</b-button></td>

                </tr>


                </tbody>
            </table>
        </div>


    </Container>


</template>

<script>
import Container from '../components/Container'
import User from '@/services/user'
import dayjs from 'dayjs'
import {mapActions} from "vuex";
import {BButton} from 'bootstrap-vue/src/components/button'
import {BImg} from 'bootstrap-vue/src/components/image'


export default {
    name:'Home',
    components:{
        Container,
        BButton,
        BImg
    },
    data(){
        return {
            list_license : [],
            items:[
                {
                    key:'test',
                    onclick: this.recovery,
                    value:'กู้คืนไลเซนส์'
                }
            ]
        }
    },

    methods:{
        ...mapActions(['setLoading']),
        async fetchLicense(){
            await this.setLoading( true)
            try{
                this.list_license = await User.getLicense()
                await this.setLoading( false)

            } catch(err){
                await this.setLoading( false)
                await this.$swal.fire('เกิดข้อผิดพลาด',err.message,'error')

            }
        },
        dateCalculate(item){
            // let now = dayjs('2021-05-01 00:00:00')
            let now = dayjs()
            let last = dayjs(item.last_payment)
            let due = now.set('date',item.payment_day_due)

            console.log(now.diff(last, 'days'))
            if (now.diff(last,'month') >= 1) {
                return `<div class="text-colorxred">ค้างชำระมา ${now.diff(last, 'day')} วัน</div>`
            }

            if (now.diff(last, 'days') <= 25){
                return '<div class="text-colorxgreen">ชำระแล้ว</div>'
            }

            return `<div class="text-colorxyellow">ครั้งต่อไปอีก ${due.diff(now, 'days')} วัน</div>`



        },
        vTofunction(v){
            switch (v) {
                case 'v1.0':
                    return 'ฝาก/ถอน'
                case 'v0.3':
                    return 'ฝาก';
                case 'v0.2':
                    return 'ถอน'
            }
        },
        async recovery(){
            let result = await this.$swal.fire({
                title: 'ใส่ Recovery Code',
                text:'สามารถติดต่อขอได้ที่ผู้ดูแล',
                input: 'text',
                inputPlaceholder:'ใส่โค้ด 6 หลัก XXXXXX',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'กู้คืน',
                cancelButtonText:'ยกเลิก',
                showLoaderOnConfirm: true,
                preConfirm: (code) => {
                    return User.recoveryLicense(code).then(resp =>{
                        return resp
                    }).catch(err=>{
                        this.$swal.showValidationMessage(
                            `เกิดข้อผิดพลาด: ${err.message}`
                        )
                    })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
            if (result.isConfirmed) {
                await this.fetchLicense()
                await this.$swal.fire({
                    title: `สำเร็จ`,
                    text:result.value.message
                })
            }
        }
    },
    async created() {
        await this.fetchLicense()
    }
}
</script>