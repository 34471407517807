import api from './index'

class User {
    getLicense() {
        return api.get(`/user/license`)
    }
    recoveryLicense(payload){
        return api.post('/user/recovery',{
            code:payload
        })
    }

}

export default new User()